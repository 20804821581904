import React from "react";
import { MenuItemInterface } from "../constants/constants";
//  interface MenuItemInterface {
//     label?: string;
//     reference?: string;
//     containerStyle?: string;
//     linkStyle?: string;
//     onclick?: Function;
//   }

let MenuItem: React.FC<MenuItemInterface> = (props) => {
  return (
    <li
      className={`flex justify-center py-8px px-24px ${props.containerStyle}`}
    >
      <a
        href={props.reference}
        className={`${props.linkStyle}`}
        onClick={() => props.onclick}
      >
        {props.label}
      </a>
    </li>
  );
};

export default MenuItem;
