import React, { useState, useEffect } from "react";
// import Carousel from "../components/carousel/carousel";
import DeviceCarousel from "../components/carousel/deviceCarousel";
// Add more image imports as needed

let LaptopImageCarousel = () => {
  return (
    <div className="w-9/12 py-16 h-[750px] mx-auto relative">
      <div className="pb-[50px] flex flex-col text-center">
        <span className="xs:text-xss_h2 md:text-ms_h2 lg:text-h2 font-bold w-full text-center">Web App Development</span>
        <span className="xs:text-sh2 md:text-sh1 lg:text-h4 text font-normal leading-5">Dynamic. Responsive. Modern</span>
      </div>
      <DeviceCarousel/>
    </div>
  );
};

export default LaptopImageCarousel;
