import React, { useState } from "react";
import SocialMediaIcon from "../components/socialMediaIcon";
import SelectInput from "../components/selectInput";
import { SOCIAL_MEDIA, TITLE_LIST } from "../constants/constants";
import TextInput from "../components/textInput";
import APIService from "../api/api";
import toast from "react-hot-toast";

interface FormData {
  title__c: string;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const Contact: React.FC = () => {

  const apiService = new APIService();

  const [formData, setFormData] = useState<FormData>({
    title__c: "",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log('TITLE 2 =>> ' + formData.title__c);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    apiService.sendContactUs({
      customerName: `${formData.title__c} ${formData.firstName} ${formData.lastName}`,
      email: formData.email, 
      message: formData.message
    })
    .then(res=>{
      toast.success('Message Sent!')
      setFormData({
        title__c: "",
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      })
    })
    .catch(err=>{
      toast.error("Can't submit message right now, Please use the email below to contact us instead")
    })
    // You can perform further actions here, such as API calls or form validation
  };

  return (
    <div className="bg-white ">
      <div className="flex flex-col gap-24px md:flex-row w-9/12 py-16 h-max mx-auto relative  justify-between">
        {/* text and social media  */}
        <div className="flex flex-col justify-between">
          <div className="flex flex-col">
            <span className="text-xss_h2 md:text-ms_h2 lg:text-h2 font-bold leading-none py-12px">
              Let's connect
            </span>
            <span className="text-sh2 md:text-sh1 lg:text-h4 leading-none py-12px">
              E-mail. WhatsApp. Social Media
            </span>
          </div>
          <div className="flex flex-col">
            <span className="">reprojecbiz@gmail.com</span>
            <span className="">+1 (876) 812-3292</span>
            <span className="">+1 (876) 528-1877</span>
          </div>
          <div className="flex py-12px">
            {SOCIAL_MEDIA.map((icon__c) => (
              <SocialMediaIcon key={icon__c.id} imgUrl={icon__c.imgUrl} alt={icon__c.alt} redirectUrl={icon__c.url} />
            ))}
          </div>
        </div>
        {/* form  */}
        <div className="flex flex-col ">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap justify-between gap-12px md:flex-nowrap py-8px">
              <SelectInput
                label="Title"
                id="title"
                name="title__c"
                titles={TITLE_LIST} 
                onOptionChange={handleInputChange} 
                 />
              <TextInput
                label={"First Name"}
                id={"firstName"}
                name={"firstName"}
                placeholder={"John"}
                onchange={handleInputChange}
                value={formData.firstName}
                type={"text"}
              />
              <TextInput
                label={"Last Name"}
                id={"lastName"}
                name={"lastName"}
                placeholder={"Doe"}
                onchange={handleInputChange}
                value={formData.lastName}
                type={"text"}
              />
            </div>
            <div>
              <TextInput
                label={"Email"}
                id={"email"}
                name={"email"}
                placeholder={"example@reprojec.com"}
                onchange={handleInputChange}
                value={formData.email}
                type={"email"}
              />
            </div>
            <div className="py-12px">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                className="flex w-full min-h-32 border-2 border-light_grey_xxx__c rounded-lg items-center justify-center pl-4 mb-24px"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                maxLength={500}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue__c text-white px-8 py-3 rounded-lg font-bold flex items-center justify-center"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
