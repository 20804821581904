import React, { FC } from "react";

interface SocialMediaInterface{
  imgUrl: string;
  alt: string;
  redirectUrl: string;
}

const SocialMediaIcon: FC<SocialMediaInterface> = (props) => {
  return (
    <a href={props.redirectUrl}>
      <div className="border-2 border-light_grey_xxx__c w-52px__c h-52px__c rounded-lg flex items-center justify-center mr-4">
        <img src={props.imgUrl} alt={props.alt} className="my-auto" />
      </div>
    </a>
  );
};

export default SocialMediaIcon;
