
interface ApiRequest {
    url: string,
    method: string,
    headers?: any,
    data?: any
}

class APIService {
    API_BASE_URL: any = process.env.REACT_APP_API_BASE_URL

    async apiRequest(requestConfig: ApiRequest) {
        const options = {
            method: requestConfig.method.toUpperCase(),
            body: JSON.stringify(requestConfig.data),
            headers: {
                'Content-Type': 'application/json',
                ...requestConfig.headers
            }
        };

        let response: Response;
        response = await fetch(`${this.API_BASE_URL}${requestConfig.url}`, options)
        return response.json();
    }

    async sendQuoteEmail(request: {fullName: string, contactNumber: string, email: string, budget: string, serviceType: string, projectDescription: string}) {
        return await this.apiRequest({
            url: '/email/get-quote',
            method: 'POST',
            data: request
        })
    }

    async sendContactUs(request: {customerName: string, email: string, message: string}) {
        return await this.apiRequest({
            url: '/email/contact-us',
            method: 'POST',
            data: request
        })
    }

}

export default APIService;