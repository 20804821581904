import React, { useState } from "react";
import Dropdown from "../components/dropdown";
import { CATEGORY_DROPDOWN, QUOTE_CARDS } from "../constants/constants";
import QuoteCard from "../components/quoteCard";
import Button from "../components/button";
import QuoteForm from "../components/quoteForm";

const Pricing = () => {

  let [selectedService, setSelectedService] = useState('')

  return (
    <div className="w-9/12 py-16 h-max mx-auto relative">
      <div className="flex flex-col items-center mb-62px">
        <span className="items-center text-xss_h2 md:text-ms_h2 lg:text-h2 font-bold">Pricing</span>
        <span className="items-center text-sh2 md:text-sh1 lg:text-h4">
          Our services are designed to cater to your specific needs
        </span>
      </div>
      {CATEGORY_DROPDOWN.map((dropdown) => (
        <div className="mb-24px">
          <Dropdown
            key={dropdown.id}
            title={dropdown.title}
            content={dropdown.content}
          />
        </div>
      ))}

      <div className="mb-8">
        <span className="font-bold text-sh2 md:text-sh1 lg:text-h4">Note</span>
        <ul className="list-disc list-inside">
          <li className="mb-2">A feature set is a collection of actions/features that would be need to accomplish a given set of goals, Example: Customer authentication feature set would include Login, Signup, Social Authentication, password reset and more.</li>
          <li className="mb-2">The number of features in a feature set is dependent on the nature of the feature set itself. more complex feature sets will have more features while simplier feature feature sets will have less features</li>
          <li className="mb-2">Addtional features sets are charged at $ 1,000 USD per feature set for non enterprise customers</li>
        </ul>
      </div>

      {/* Can't decide container  */}
      <div className="bg-white h-max rounded-lg p-8">
        <h2 className="font-bold text-xss_h2 md:text-ms_h2 lg:text-h2">Can't decide?</h2>
        <h3 className="font-bold text-sh2 md:text-sh1 lg:text-h4 ">Request a Quote.</h3>
        <span>
          What services are you interested in? Select all which are applicable
        </span>
        <div className=" md:grid md:grid-cols-3 flex flex-wrap justify-center gap-12px mt-6">
          {QUOTE_CARDS.map((card) => (
            <QuoteCard key={card.id} title={card.title} imgUrl={card.imgUrl} onSelect={()=>setSelectedService(card.title)} selected={selectedService === card.title}/>
          ))}
        </div>
        <div className="my-6">
          <QuoteForm selectedService={selectedService}/>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
