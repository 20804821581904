import React from "react";
import "./loader.css";

let Loader = () => {
  return (
    <div className="h-[385px] grid place-items-center">
      <div className="loader "></div>
    </div>
  );
};

export default Loader;
