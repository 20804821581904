import React from "react";
import Card from "../components/card";
import { OFFER_CARDS } from "../constants/constants";

const ServiceSection = () => {
  return (
    <div className="grid bg-white py-20">
      <div className="h-full w-9/12 justify-self-center grid justify-items-center">
        <div className="pb-10">
          <span className="text-xss_h2 md:text-ms_h2 lg:text-h2 font-bold ">
            What we offer
          </span>
        </div>
        <div className="flex flex-wrap gap-24px justify-center max-w-full">
          {OFFER_CARDS.map((card) => (
            <Card
              key={card.id}
              imgUrl={card.imgUrl}
              heading={card.heading}
              content={card.content}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
