import React from "react";
import { Url } from "url";

// The following are the interface structures
interface OfferCardObject {
  id: number;
  imgUrl: String;
  heading: String;
  content: String;
}

interface PriceCardObject {
  id: number;
  category: String;
  minPrice: String;
  content: String;
}

interface CatergoryDropdownsOject {
  id: number;
  title: string;
  content: any[];
}

interface QuoteCardObject {
  id: number;
  title: string;
  imgUrl: any;
}

interface SocialMediaObject {
  id: number;
  imgUrl: any;
  alt: string;
  url: any;
}

export interface SelectInputInterface {
  id: any;
  name: any;
  label: string;
  titles: any[];
  onOptionChange: any;
}

export interface ServiceCardInterface {
  imgUrl: any;
  heading: String;
  content: String;
}

export interface MenuItemInterface {
  label?: string;
  reference?: string;
  containerStyle?: string;
  linkStyle?: string;
  onclick?: Function;
}

// The following are lists of interface instances
export const OFFER_CARDS: OfferCardObject[] = [
  {
    id: 1,
    imgUrl: "/images/dev.svg",
    heading: "Web App Development",
    content:
      "Development of Single page, Dashboards, Ecommerce websites and more.",
  },
  {
    id: 2,
    imgUrl: "/images/mobile.svg",
    heading: "Mobile App Development",
    content:
      "Custom mobile application development to meet all your business and customer needs.",
  },
  {
    id: 3,
    imgUrl: "/images/designs.svg",
    heading: "UI/UX Design",
    content:
      "Custom modern interface designs catered to your specific business needs and use cases.",
  },
  {
    id: 4,
    imgUrl: "/images/maintenance.svg",
    heading: "Maintenance",
    content:
      "Maintenance of your systems, from server and database backups, certificate renewals and more.",
  },
];

export const CATEGORY_DROPDOWN: CatergoryDropdownsOject[] = [
  {
    id: 1,
    title: "Web App Development",
    content: [
      {
        id: 1,
        category: "Single Page Website",
        minPrice: "2,000",
        content:
          "Single Page website. Great for product catalogs, store front, business information and more.",
      },
      {
        id: 2,
        category: "Custom Dashboard",
        minPrice: "6,000",
        content:
          "Full Features dashboard for monitoring and administration or online stores, system or viewing data.",
        isList: true,
        list: [
          "6 Main feature sets",
          "API Development",
          "Database Design and integration",
          "Custom UI Design",
          "Interface Development",
        ],
      },
      {
        id: 3,
        category: "Custom Web Application",
        minPrice: "5,000",
        content:
          "Full features web app suitable for shipping company website, online store, internal applications and more.",
        isList: true,
        list: [
          "6 Main feature sets",
          "API Development",
          "Database Design and integration",
          "Custom UI Design",
          "Interface Development",
        ],
      },
      {
        id: 4,
        category: "Enterprise Web Application",
        minPrice: "Custom",
        noPrice: true,
        content:
          "Full features Web Application suitable for shipping company website, online store, internal applications and more. Designed and built to meet the needs of our enterprise customers.",
        isList: true,
        list: ["Contact us for a quote"],
      },
    ],
  },
  {
    id: 2,
    title: "Mobile App Development",
    content: [
      {
        id: 1,
        category: "Mobile Application Development",
        minPrice: "6,000",
        content:
          "Full features Mobile App suitable for shipping company website, online store, internal applications and more.",
        isList: true,
        list: [
          "6 Main feature sets",
          "API Development",
          "Database Design and integration",
          "Custom UI Design",
          "Interface Development",
          "Etc",
        ],
      },
      {
        id: 2,
        category: "Enterprise Mobile Application",
        minPrice: "Custom",
        noPrice: true,
        content:
          "Full features Mobile App suitable for shipping company website, online store, internal applications and more. Designed and built to meet the needs of our enterprise customers.",
        isList: true,
        list: ["Contact us for a quote"],
      },
    ],
  },
  {
    id: 3,
    title: "UI/UX Design",
    content: [
      {
        id: 1,
        category: "Application Maintenance",
        minPrice: "Custom",
        noPrice: true,
        content:
          "Custom UI/UX Designs to meet the specific business usecase or customer needs.",
        isList: true,
        list: ["Contact us for a quote"],
      },
    ],
  },
  {
    id: 4,
    title: "Maintenance",
    content: [
      {
        id: 1,
        category: "Application Maintenance",
        minPrice: "Custom",
        noPrice: true,
        content:
          "We offer services such as updating, patching, bug fixing, migration and more.",
        isList: true,
        list: ["Contact us for a quote"],
      },
    ],
  },
];

export const QUOTE_CARDS: QuoteCardObject[] = [
  {
    id: 1,
    title: "Web App Development",
    imgUrl: "/images/dev.svg",
  },
  {
    id: 2,
    title: "Mobile App Development",
    imgUrl: "/images/mobile.svg",
  },
  {
    id: 3,
    title: "UI/UX Design",
    imgUrl: "/images/designs.svg",
  },
];

export const SOCIAL_MEDIA: SocialMediaObject[] = [
  {
    id: 1,
    imgUrl: "/images/facebook.svg",
    alt: "Facebook icon",
    url: "https://www.facebook.com/profile.php?id=61556338887603",
  },
  {
    id: 2,
    imgUrl: "/images/instagram.svg",
    alt: "Instagram Icon",
    url: "https://www.instagram.com/reprojec_ja?igsh=MW9yZ2NsZ3djeGlvdw==",
  },
  {
    id: 3,
    imgUrl: "/images/linkedin.svg",
    alt: "LinkedIn Icon",
    url: "https://www.linkedin.com/company/reprojec/",
  },
];

// LISTS
export const TITLE_LIST: String[] = ["Mr", "Mrs", "Miss", "Ms"];
