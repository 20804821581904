import React from "react";
import "./App.css";
import Navbar from "./components/navbar";
import Welcome from "./sections/welcome";
import ServiceSection from "./sections/serviceSection";
import OurWork from "./sections/ourWork";
import Contact from "./sections/contact";
import Pricing from "./sections/pricing";
import Footer from "./sections/footer";

function App() {
  return (
    <div className="w-screen bg-background-grey">
      <Navbar />
      <div className="welcome" id="welcome">
        <Welcome />
      </div>
      <div id="services">
        <ServiceSection />
      </div>
      <div id="projects">
        <OurWork />
      </div>
      <div className="border-2 border-white bg-white h-2" />
      <div id="pricing">
        <Pricing />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
