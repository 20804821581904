import React from "react";
import { ServiceCardInterface } from "../constants/constants";

const Card: React.FC<ServiceCardInterface> = (props) => {
  return (
    <div className="border-2 border-c_border_grey max-w-60 rounded-lg py-4 px-2 flex flex-col items-center">
      <div className=" bg-light_blue_x__c rounded-lg p-4 mb-4 w-24 flex justify-center">
        <img src={props.imgUrl} />
      </div>
      <span className="font-bold">
        {props.heading}
      </span>
      <p className="text-center w-9/12">
        {props.content}
      </p>
    </div>
  );
};

export default Card;
