import React, { useState } from "react";
import MenuItem from "./menuItem";
// import MENU from "/public/images/menu-icon.svg" //"/images/menu-icon.svg"

let Navbar = () => {
  // STATE VARIABLES
  let [isVisible, setIsVisible] = useState(false); //boolean to toggle nav menu on mobile view
  let [menuStyle, setMenuStyle] = useState("h-[0px]"); //state variable for the menu style
  let [navIcon, setNavIcon] = useState("/images/menu-icon.svg"); //state variable for the nav icon

  // COMPONENT METHODS
  // This method sets the nav menu visibility
  let handleMenuVisibility = () => {
    setIsVisible(!isVisible);
    if (isVisible) {
      setMenuStyle("h-screen ");
      setNavIcon("/images/close-icon.svg");
    } else {
      setMenuStyle("h-[0px]");
      setNavIcon("/images/menu-icon.svg");
    }
  };

  return (
    <nav className="py-[12px] md:py-[18px] fixed top-0 left-0 right-0 z-50 px-[20px] bg-white md:px-[6%] md:text-[16px] md:font-normal">
      <div className="w-full md:flex-row flex flex-col justify-between items-center">
        {/* Nav Icons  */}
        <div className=" flex w-full md:w-max flex-row justify-between items-center ">
          <div className="">
            <a href="#welcome">
              <img
                src="/images/logo1.svg"
                alt="Company Logo"
                className="md:invisible md:h-0 md:w-0 h-[24px] w-[24px] "
              />
              <img
                src="/images/logo.svg"
                alt="Company Logo"
                className="invisible h-0 w-0 md:visible md:h-[28px] md:w-max"
              />
            </a>
          </div>
          <div
            onClick={() => handleMenuVisibility()}
            className="px-12px md:hidden flex justify-center items-center"
          >
            <img src={navIcon} alt="Menu icon" className="w-[24px] h-[24px]" />
          </div>
        </div>
        {/* Nav Menu  */}
        <ul
          className={` ${menuStyle}  overflow-y-hidden transition-height duration-[600ms] flex xs:flex-col md:flex-row items-center md:justify-center md:h-max  text-nav_text-grey text-[16px]
            font-normal  md:text-[20px] md:font-semibold pt-[32px] md:pt-0`}
        >
          <MenuItem
            label="Services"
            reference="#services"
            onclick={() => handleMenuVisibility()}
          />
          <MenuItem
            label="Products"
            reference="#projects"
            onclick={() => handleMenuVisibility()}
          />
          <MenuItem
            label="Pricing"
            reference="#pricing"
            onclick={() => handleMenuVisibility()}
          />
          <MenuItem
            label="Contact"
            reference="#contact"
            onclick={() => handleMenuVisibility()}
          />
        </ul>
        {/* Nav Button  */}
        <button
          className={`invisible h-0 md:h-max md:visible flex justify-center`}
        >
          <a
            href="#pricing"
            className="text-white bg-blue__c py-8px px-24px rounded-lg font-bold display flex items-center justify-center"
            onClick={handleMenuVisibility}
          >
            Can't Decide?
          </a>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
