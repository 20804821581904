import React, { useState } from "react";
import TextInput from "./textInput";
import SelectInput from "./selectInput";
import { TITLE_LIST } from "../constants/constants";
import APIService from "../api/api";
import QuoteCard from "./quoteCard";
import toast from "react-hot-toast";

interface QuoteFormData {
  minPrice: number;
  maxPrice: number;
  title__c: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNum: string;
  projectDetail: string;
}

const QuoteForm: React.FC<{selectedService: string}> = (props) => {

  const apiService = new APIService();

  const [quoteFormData, setQuoteFormData] = useState<QuoteFormData>({
    minPrice: 0,
    maxPrice: 0,
    title__c: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNum: "",
    projectDetail: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setQuoteFormData({
      ...quoteFormData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    apiService.sendQuoteEmail({
      fullName: `${quoteFormData.title__c} ${quoteFormData.firstName} ${quoteFormData.lastName}`,
      contactNumber:  quoteFormData.contactNum,
      email: quoteFormData.email,
      budget: `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quoteFormData.minPrice)} - ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quoteFormData.maxPrice)}`,
      serviceType: 'N/A',
      projectDescription: quoteFormData.projectDetail
    })
    .then(res=>{
      toast.success('Quote Request Sent!')
      setQuoteFormData({
        minPrice: 0,
        maxPrice: 0,
        title__c: "",
        firstName: "",
        lastName: "",
        email: "",
        contactNum: "",
        projectDetail: "",
      })
    })
    .catch(err=>{
      toast.error("Can't submit quote right now, Please use the email below to contact us instead")
    })
  };

  return (
    <form className="flex flex-wrap-reverse lg:flex-nowrap lg:flex-row-reverse  gap-24px " onSubmit={handleSubmit}>
      
      {/* Right side of the form  */}
      <div className="flex flex-wrap w-full">
        <div className="w-full">
          <span>What is your budget like?</span>
          <div className="flex flex-wrap justify-between gap-12px md:grid md:grid-cols-2 py-8px">
            <TextInput
              label={""}
              id={"minPrice"}
              name={"minPrice"}
              placeholder="Min Price"
              onchange={handleInputChange}
              value={
                quoteFormData.minPrice === 0 ? null : quoteFormData.minPrice
              }
              type={"number"}
            />
            <TextInput
              label={""}
              id={""}
              name={"maxPrice"}
              placeholder={"Max Price"}
              onchange={handleInputChange}
              value={
                quoteFormData.maxPrice === 0 ? null : quoteFormData.maxPrice
              }
              type={"number"}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-between gap-12px md:flex-nowrap py-8px">
          <SelectInput
            label="Title"
            id="title"
            name="title__c"
            titles={TITLE_LIST}
            onOptionChange={handleInputChange}
          />
          <TextInput
            label={"First Name"}
            id={"firstName"}
            name={"firstName"}
            placeholder={"John"}
            onchange={handleInputChange}
            value={quoteFormData.firstName}
            type={"text"}
          />
          <TextInput
            label={"Last Name"}
            id={"lastName"}
            name={"lastName"}
            placeholder={"Doe"}
            onchange={handleInputChange}
            value={quoteFormData.lastName}
            type={"text"}
          />
        </div>
        <div className="flex flex-wrap justify-between gap-12px md:grid md:grid-cols-2 w-full py-8px">
          <TextInput
            label={"Email"}
            id={"email"}
            name={"email"}
            placeholder={"example@reprojec.com"}
            onchange={handleInputChange}
            value={quoteFormData.email}
            type={"email"}
          />
          <TextInput
            label={"Phone"}
            id={""}
            name={"contactNum"}
            placeholder={"1(876)000-0000"}
            onchange={handleInputChange}
            value={quoteFormData.contactNum}
            type={"text"}
          />
        </div>
        <button
          type="submit"
          className=" bg-blue__c w-full rounded-lg text-white font-bold p-2 text-lg h-max mt-6"
        >
          Submit
        </button>
      </div>
      {/* Left side of the form  */}
      <div className="flex flex-col w-full">
        <label htmlFor="projectDetail">Tell us about your project</label>
        <textarea
          id="projectDetail"
          name="projectDetail"
          value={quoteFormData.projectDetail}
          placeholder="What is your project about?"
          className="border-2 border-light_grey_xxx__c rounded-lg p-2 h-full "
          onChange={handleInputChange}
        />
      </div>
    </form>
  );
};

export default QuoteForm;
