import React from "react";

const PriceCard = (props: {
  category: String;
  minPrice: String;
  content: String;
  isList?: boolean;
  list?: [string];
  noPrice?: boolean;
}) => {
  return (
    <div className="bg-white max-w-300px flex items-center flex-col max-h-500px h-max p-30px rounded-lg space-y-2.5">
      <h3 className="font-bold text-lg text-blue__c flex text-center">{props.category}</h3>
      <div className="flex items-baseline flex-row">
        {props.noPrice ? <h1 className="font-bold text-h4">{props.minPrice}</h1> : null}
        {!props.noPrice ? <h1 className="font-bold text-h2">{props.minPrice}</h1> : null}
        {!props.noPrice ? <sub className="font-normal">USD</sub> : null}
        {/* <span className="">Minimum</span> */}
      </div>
      <p className="max-h-300px text-ellipsis overflow-hidden font-semibold ...">{props.content}</p>
      {props.isList&&props.list ? (
        <ul className="list-disc">
          {props.list.map((item, index)=>(
            <li key={`card-${props.category}-${Math.random()+index}`}>{item}</li>
          ))}
        </ul>
      ):null}
    </div>
  );
};

export default PriceCard;
