import React from "react";
import Button from "../components/button";

let Welcome = () => {
  return (
    <div className="pt-[144px] grid py-20 ">
      <div className="h-full w-9/12 justify-self-center grid justify-items-center ">
        <div className="max-w-2xl w-full flex flex-col items-center ">
          <div className="w-full ">
            <img src="/images/hero.svg" />
          </div>
          <div className="lg:text-h1 md:text-ms_h1 text-h3 font-bold flex flex-col items-center text-center pb-8 ">
            <span className="">Powerful & Immersive</span>
            <span className="text-blue__c leading-none">Digital Products</span>
          </div>
          <div>
            <p className="text-center pb-8">
              Progech is a full-service mobile and web design and development
              firm. We focus on building scalable applications and immersive
              user experience
            </p>
          </div>
          <div>
            <a
              href="#contact"
              className="text-blue__c bg-transparent py-8px px-24px rounded-lg font-bold display flex items-center justify-center"
            >
              Let's connect
              <img
                src="/images/arrow-right.svg"
                alt="Right icon"
                className="pl-2"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
