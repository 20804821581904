import React, { useState } from "react";
import PriceCard from "./priceCard";

const Dropdown = (props: { title: string; content: any[] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div
        className="cursor-pointer  flex flex-row justify-between border-b-2 border-light_grey_xxx__c pb-4"
        onClick={toggleCollapse}
      >
        <span>{props.title}</span>
        {/* display the appropriate icon  */}
        {isOpen ? (
          <img src="/images/minus.svg" alt="" />
        ) : (
          <img src="/images/add.svg" alt="" />
        )}
      </div>
      <div className="flex flex-wrap justify-center gap-24px pt-24px">
        {
          // if boolean is try then make content visible
          isOpen
            ? props.content.map((card, index) =>
              // if category matches title then display
              <PriceCard
                key={index}
                category={card.category}
                minPrice={card.minPrice}
                content={card.content}
                isList={card?.isList}
                list={card?.list}
                noPrice={card?.noPrice}
              />
            )
            : null
        }
      </div>
    </div>
  );
};

export default Dropdown;
