import React, { useState } from "react";

const QuoteCard = (props: { title: string; imgUrl: any; onSelect: Function; selected?: boolean }) => {
  // const [selectedService, setSelectedService] = useState("");
  // // When a card for a specific service is selected,
  // // the following method is then called to handle the process
  // const handleServiceSelection = () => {
  //   setSelectedService(props.title);

  //   const customEvent = new CustomEvent("service", {
  //     detail: {
  //       value: { selectedService },
  //       // You can include additional data in the 'detail' property
  //     },
  //   });
    
  //   props.onOptionChange(customEvent);
  // };
  return (
    <div
      className={`border-2 border-light_grey_xxx__c rounded-lg flex flex-row p-12px lg:p-24px items-center cursor-pointer bg-${props.selected ? 'blue__c' : 'white'} text-${props.selected ? 'white' : 'black'} hover:bg-blue__c hover:text-white `}
      onClick={()=>{
        props.onSelect(props.title)
      }}
    >
      <div className="bg-light_blue_x__c rounded-lg p-12px mr-6px lg:mr-12px flex justify-center">
        <img
          src={props.imgUrl}
          alt=""
          className=" w-24px h-24px lg:h-32px lg:w-32px"
        />
      </div>
      <span className="font-bold w-24 text-sm lg:text-lssb">
        {props.title}
      </span>
    </div>
  );
};

export default QuoteCard;
