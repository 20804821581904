import React, { FC, useState } from "react";
import Loader from "../loader/loader";

interface DeviceCarouselInterface {}

let DeviceCarousel: FC<DeviceCarouselInterface> = (props) => {
  let [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  let [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  // List of slide screenshot images

  let MOBILE_SCREENSHOTS = [
    "/images/mobile-ss1.svg",
    "/images/mobile-ss2.svg",
    "/images/mobile-ss3.svg",
  ];

  let [screenshotList, setScreenshotList] =
    useState<String[]>(MOBILE_SCREENSHOTS);

  let TABLET_SCREENSHOTS = [
    "/images/tablet-ss1.svg",
    "/images/tablet-ss2.svg",
    "/images/tablet-ss3.svg",
  ];

  let DESKTOP_SCREENSHOTS = [
    "/images/desktop-ss1.svg",
    "/images/desktop-ss2.svg",
    "/images/desktop-ss3.svg",
  ];

  let deviceIcons = [
    "/images/mobile-icon.svg",
    "/images/tablet-icon.svg",
    "/images/desktop-icon.svg",
  ];

  let deviceFrame = [
    "/images/mobile-slide-frame.svg",
    "/images/tablet-slide-frame.svg",
    "/images/laptop-slide-frame.svg",
  ];

  let onDeviceFrameChange = (index: React.SetStateAction<number>) => {
    setLoading(true);
    setScreenshotList(() =>
      index === 0
        ? MOBILE_SCREENSHOTS
        : index === 1
        ? TABLET_SCREENSHOTS
        : index === 2
        ? DESKTOP_SCREENSHOTS
        : []
    );
    setCurrentFrameIndex(index);
    setLoading(false);
    // setTimeout(() => {
    //   setCurrentFrameIndex(index);
    //   setLoading(false);
      
    // }, 1000); // Change this value as needed
  };

  return (
    <div className=" h-[510px] carousel relative overflow-hidden">
      {loading ? (
        <Loader />
      ) : (
        <div className=" carousel-inner relative flex justify-center">
          
          {deviceFrame.map((image, frameIndex) => (
            <div
              key={frameIndex}
              className={`opacity-0 ml-auto carousel-item absolute transition-opacity duration-[300ms] ${
                frameIndex === currentFrameIndex ? "opacity-100" : "opacity-0"
              }`}
            >
              {/*-------------------------------------------------------------------*/}
              <div className="absolute w-full pt-[8px] mix-blend-multiply">
                <div className="h-[480px] carousel relative overflow-hidden ">
                  <div className="carousel-inner relative flex justify-center">
                    {screenshotList.map((image: any, index) => (
                      <div
                        key={index}
                        className={`opacity-0 ml-auto carousel-item absolute transition-opacity duration-[1000ms]  ${
                          index === currentScreenshotIndex
                            ? "opacity-100"
                            : "opacity-0"
                        }`}
                      >
                        <img
                          className={` w-[188px] h-[384px] ${
                            currentFrameIndex === 0
                              ? "w-[490px] translate-y-[1%]"
                              : currentFrameIndex === 1
                              ? " w-[490px] translate-y-[2%]"
                              : "w-[560px] translate-y-[-1%]"
                          }`}
                          src={image}
                          alt={`screenshot ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                  <div className=" carousel-nav absolute bottom-0 left-0 right-0 flex justify-center items-center">
                    {screenshotList.map((_: any, index: any) => (
                      <div
                        key={index}
                        className={` carousel-nav-item w-[12px] h-[12px] mx-1 rounded-full transition-colors  ${
                          index === currentScreenshotIndex
                            ? "bg-blue-500 h-[14px] w-[14px]"
                            : "bg-gray-300"
                        }`}
                        onClick={() => setCurrentScreenshotIndex(index)} //setCurrentIndex(index)
                      />
                    ))}
                  </div>
                </div>
              </div>

              {/*-------------------------------------------------------------------*/}
              <img
                className=" h-[405px]"
                src={image}
                alt={`screenshot ${frameIndex + 1}`}
              />
            </div>
          ))}
        </div>
      )}
      <div className=" h-full right-0 absolute flex items-center">
        <div className=" h-max flex flex-col gap-[20px]">
          {deviceIcons.map((device, frameIndex) => (
            <img
              key={frameIndex}
              className={`h-[48px] w-[48px] hover:cursor-pointer ${
                frameIndex === currentFrameIndex ? "opacity-100" : ""
              }`}
              src={device}
              alt="device icons"
              onClick={() => onDeviceFrameChange(frameIndex)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeviceCarousel;
