import React, { useState } from "react";
import { SelectInputInterface } from "../constants/constants";

const SelectInput: React.FC<SelectInputInterface> = (props) => {
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleTitleChange = (event:any) => {
    setSelectedTitle(event.target.value);
    props.onOptionChange(event);
  };

  return (
    <div className="flex flex-col w-full md:w-max">
      <label htmlFor={props.name}>{props.label}</label>
      <select
        id={props.id}
        name={props.name}
        value={selectedTitle}
        onChange={handleTitleChange}
        className="border-2 border-light_grey_xxx__c rounded-lg p-2 text-light_grey_x__c"
      >
        {props.titles.map((title) => (
          <option value={title.toLowerCase()}>{title}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
