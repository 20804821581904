import React  ,{FC} from 'react'

interface FooterInterface {
}

let Footer : FC<FooterInterface> = () => {
  return (
    <div className='py-[40px] w-screen flex justify-center text-[16px] text-[#808080]'>
      <div className='md:w-9/12 flex flex-col justify-center gap-y-[12px]'>
        <div className='border-[0.5px] border-[#bcbcbf] w-full h-0'/>
        <div className='flex justify-between w-full h-max gap'>
          <span>Copyright &copy; 2024 Reprojec. All Rights Reserved</span>
          <span className=''>
            <a className='pr-[8px] mr-[8px] border-r-[1px] border-r-[#bcbcbf]' href="">Privacy Policy</a>
            <a className='pr-[8px] mr-[8px] border-r-[1px] border-r-[#bcbcbf]' href="">Terms of Use</a>
            <a href="">Legal</a>
          </span>
          <span>Jamaica</span>
        </div>
      </div>
    </div>
  )
}

export default Footer