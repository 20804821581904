import React from "react";
// props interface
interface TextInputInterface {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  onchange: any;
  value: any;
  type: any;
}

const TextInput: React.FC<TextInputInterface> = (props) => {
  return (
    <div className="w-full ld:w-auto">
      <label htmlFor={props.name}>{props.label}</label>
      <input
        id={props.id}
        name={props.name}
        className="border-2 rounded-lg border-light_grey_xxx__c p-2 pl-4 w-full"
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onchange}
      />
    </div>
  );
};

export default TextInput;
